import {types as t} from './actions';

export const initState = {
  visible: false,
  type: 'login'
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case t.SET_MODAL_VISIBLE:
      return {...state, visible: payload};

    case t.SET_MODAL_TYPE:
      return {...state, type: payload};

    default:
      return state;
  }
};
