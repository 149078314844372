import {asyncAction, createTypes} from 'redux-action-types';
import {ajax} from '../../api/ajax';
import {URL_SEARCH_AVAILABLE_TABS} from '../../api/routes';

export const types = createTypes('SearchTabs.', asyncAction('GET_TABS'));

export const getTabs = countryCode => (dispatch, getState) => {
  const {
    locate: {language}
  } = getState();

  dispatch({type: types.GET_TABS_REQUEST});

  return ajax({
    url: URL_SEARCH_AVAILABLE_TABS(countryCode.toUpperCase()),
    cb: res => dispatch({type: types.GET_TABS_SUCCESS, payload: res.data}),
    err: error => dispatch({type: types.GET_TABS_FAIL, payload: error}),
    language: language === 'uk' ? 'UA' : language.toUpperCase()
  });
};
