import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import reducers from './rootReducer';

export default (initState = {}) => createStore(reducers, initState, initializeMiddleware());

function initializeMiddleware() {
  return composeWithDevTools(applyMiddleware(thunkMiddleware));
}
