import {combineReducers} from 'redux';

import adaptive from './adaptive/reducer';
import locate from './locate/reducer';
import countryInfo from './countryInfo/reducer';
import homeInfo from './homeInfo/reducer';
import loginRegister from './loginRegister/reducer';
import auth from './auth/reducer';
import searchTabs from './searchTabs/reducer';
import searchFilters from './searchFilters/reducer';
import searchFields from './searchFields/reducer';
import ordersInfo from './station/reducer';
import search from './search/reducer';
import common from './common/reducer';
import bookmark from './bookmark/reducer';
import seo from './seo/reducer';
import brands from './brands/reducer';
import services from './services/reducer';
import gallery from './gallery/reducer';
import galleryFilters from './galleryFilters/reducer';
import project from './project/reducer';
import order from './order/reducer';

export default combineReducers({
  adaptive,
  auth,
  locate,
  countryInfo,
  homeInfo,
  loginRegister,
  searchTabs,
  searchFilters,
  searchFields,
  search,
  common,
  bookmark,
  seo,
  ordersInfo,
  brands,
  services,
  gallery,
  galleryFilters,
  project,
  order
});
