import {types as t} from './actions';

export const initState = {
  isBooked: false
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case t.ADD_BOOKMARK_STATION_SUCCESS:
      return {...state, isBooked: true};

    case t.ADD_BOOKMARK_STATION_FAIL:
      return {...state, bookError: payload};

    case t.REMOVE_BOOKMARK_STATION_SUCCESS:
      return {...state, isBooked: false};

    case t.REMOVE_BOOKMARK_STATION_FAIL:
      return {...state, bookError: payload};

    default:
      return state;
  }
};
