import {types as t} from './actions';

export default (state = {}, {type, payload}) => {
  switch (type) {
    case t.GET_REQUEST:
      return {
        loading: true
      };

    case t.GET_SUCCESS:
      return {
        fetched: true,
        ...payload.data
      };

    case t.GET_FAIL:
      return {
        failure: true
      };

    default:
      return state;
  }
};
