import {types as t} from './actions';

export const initState = {
  language: '',
  country: null,
  countryCode: null,
  languagesLoading: false,
  languages: [],
  countriesLoading: false,
  countries: [],
  translationsLoading: false,
  translations: [],
  localeTranslations: {}
  // alternativeLocates: []
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case t.SET_LANGUAGE:
      return {...state, language: payload};

    case t.SET_COUNTRY:
      return {...state, country: payload.country, countryCode: payload.countryCode};

    case t.GET_LANGUAGES_REQUEST:
      return {...state, languagesLoading: true};

    case t.GET_LANGUAGES_SUCCESS:
      return {...state, languagesLoading: false, languages: payload};

    case t.GET_LANGUAGES_FAIL:
      return {...state, languagesError: payload};

    case t.GET_COUNTRIES_REQUEST:
      return {...state, countriesLoading: true};

    case t.GET_COUNTRIES_SUCCESS:
      return {...state, countriesLoading: false, countries: payload};

    case t.GET_COUNTRIES_FAIL:
      return {...state, countriesError: payload};

    case t.GET_TRANSLATIONS_REQUEST:
      return {...state, translationsLoading: true};

    case t.GET_TRANSLATIONS_SUCCESS:
      return {...state, translationsLoading: false, translations: payload};

    case t.GET_TRANSLATIONS_FAIL:
      return {...state, translationsError: payload};

      // case t.SET_ALTERNATIVE_LOCATES:
      //   return {
      //     ...state,
      //     alternativeLocates: payload
      //   };

    default:
      return state;
  }
};
