import camelCase from 'lodash/camelCase';
import {types as t} from './actions';

export const initState = {
  h1: '',
  title: '',
  metaTitle: '',
  metaDescription: '',
  metaKeywords: '',
  og: {
    title: '',
    description: '',
    locale: ''
  },
  index: false,
  follow: false
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case t.SET_SEO:
      return {
        ...state,
        ...Object.keys(payload)
            .reduce((obj, field) => {
              obj[camelCase(field)] = payload[field];
              return obj;
            }, {})
      };

    case t.SET_META:
      return {
        ...state,
        ...payload,
        metaTitle: payload.title,
        metaDescription: payload.description
      };

    default:
      return state;
  }
};
