import cloneDeep from 'lodash/cloneDeep';
import {types as t} from './actions';
import get from 'lodash/get';

export const initState = {
  loading: false,
  error: null,
  results: [],
  meta: {},

  selectedId: null,
  hoveredId: null,
  areaSearchIsEnable: false,
  addressSearchIsEnable: true,
  coordinates: null,
  distance: 25,
  leftBound: 0,
  city: null,
  pageWidth: 0,
  isAllResultsObtained: false,
  urlIsChanging: false,
  forceCenterMap: false,
  zoomingMap: false,
  searchingType: null,
  forceEnableAddressSearch: false,
  isFirstSearch: true,
  parsedUrlParams: [],
  coordinatesFromSearch: {
    lat: 0,
    lng: 0
  },
  isResultsFetched: false,
  browserPosition: null,
  carPosition: null
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case t.SET_CAR_POSITION: {
      const {position} = payload;

      return {
        ...state,
        carPosition: position
      }
    }
    case t.SET_BROWSER_POSITION: {
      const {position} = payload;

      return {
        ...state,
        browserPosition: position
      }
    }
    case t.SET_SEARCHED_DATA: {
      return {
        ...state,
        isFetching: false
      }
    }
    case t.SET_PARSED_URL_PARAMS: {
      const {params = [], extra = []} = payload;
      const {parsedUrlParams} = state;
      const validParams = params.filter(param => get(param, 'value'));
      const currentPrefixes = params.map(({prefix}) => prefix);

      return {
        ...state,
        parsedUrlParams: [...parsedUrlParams.filter(
            ({prefix}) => !currentPrefixes.includes(prefix) && !extra.includes(prefix)
        ), ...validParams]
      }
    }
    case t.GET_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        results: [],
        isResultsFetched: false
      };

    case t.GET_SEARCH_SUCCESS: {
      const {
        meta: {
          attributes: {
            distance = 25,
            lat = 0,
            lng = 0
          }
        }
      } = payload;

      return {
        ...state,
        loading: false,
        results: payload.data.map(item => ({
          ...item,
          visible: true
        })),
        meta: {...payload.meta},
        coordinatesFromSearch: {
          lat,
          lng
        },
        distance: (+distance).toFixed(1),
        isResultsFetched: true
      };
    }
    case t.GET_SEARCH_FAIL:
      return {...state, loading: false, error: payload};

    case t.HOVER_SEARCH_ITEM:
      return {
        ...state,
        hoveredId: payload
      };

    case t.UNHOVER_SEARCH_ITEM:
      return {
        ...state,
        hoveredId: initState.hoveredId
      };

    case t.SELECT_SEARCH_ITEM:
      return {
        ...state,
        selectedId: payload
      };

    case t.DESELECT_SEARCH_ITEM:
      return {
        ...state,
        selectedId: initState.selectedId
      };

    case t.SET_CITY:
      return {
        ...state,
        city: payload
      };

    case t.SET_COORDINATES:
      const shiftedSwLng = payload.sw_lng + ((payload.ne_lng - payload.sw_lng) * state.leftBound) / state.pageWidth;

      return {
        ...state,
        coordinates: {
          ...payload,
          sw_lng: shiftedSwLng
        },
        results: state.results.map(result => {
          const {
            position: {lat, lng}
          } = result;

          return {
            ...result,
            visible:
                lat > payload.sw_lat
                && lat < payload.ne_lat
                && (lng < payload.ne_lng && lng > shiftedSwLng)
          };
        })
      };

    case t.SET_LEFT_BOUND:
      return {
        ...state,
        leftBound: payload
      };

    case t.SET_PAGE_WIDTH:
      return {
        ...state,
        pageWidth: payload
      };

    case t.ENABLE_AREA_SEARCH:
      return {
        ...state,
        areaSearchIsEnable: true
      };

    case t.DISABLE_AREA_SEARCH:
      return {
        ...state,
        areaSearchIsEnable: false
      };

    case t.SET_RESULTS_OBTAINING:
      return {
        ...state,
        isAllResultsObtained: payload <= 100
      };

    case t.CHANGE_URL_CHANGING_STATE:
      return {
        ...state,
        urlIsChanging: payload
      };

    case t.CHANGE_FORCE_CENTER_MAP_STATE:
      return {
        ...state,
        forceCenterMap: payload
      };

    case t.CHANGE_ZOOMING_MAP_STATE:
      return {
        ...state,
        zoomingMap: payload
      };

    case t.SORT_RESULTS_BY:
      let sortField;

      switch (payload) {
        case 'distance':
          sortField = 'distance_with_measure';
          break;
        case 'hourly_rate_desc':
          sortField = 'hourly_rate';
          break;
        default:
          sortField = payload;
          break;
      }

      return {
        ...state,
        results: state.results.sort((a, b) => (payload === 'hourly_rate_desc' ? a[sortField] - b[sortField] : b[sortField] - a[sortField])
        )
      };

    case t.SET_SEARCHING_TYPE:
      return {
        ...state,
        searchingType: payload
      };

    case t.CHANGE_FORCE_ENABLE_ADDRESS_SEARCH_STATE:
      return {
        ...state,
        forceEnableAddressSearch: payload
      };

      // Hallway cases
    case t.SET_DESIGNERS_CARDS:
      return {
        ...state,
        results: Array.of(payload)
      };

    case t.SET_NEXT_DESIGNERS_CARDS: {
      let clone = cloneDeep(state.results);
      clone[payload.meta.pagination.prev_page] = payload.data;
      return {
        ...state,
        results: clone,
        meta: payload.meta
      };
    }

    case t.SET_SEARCH_META:
      return {
        ...state,
        meta: payload
      };

    case t.SEARCH_HALLWAY_REQUEST:
      return {
        ...state,
        loading: true,
        fetched: false
      };

    case t.SEARCH_HALLWAY_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        fetched: true
      };
    case t.SEARCH_HALLWAY_REQUEST_FAILED:
      return {
        ...state,
        fetched: false,
        loading: false
      };

    default:
      return state;
  }
};
