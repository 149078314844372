import React from 'react';
import App from 'next/app';
import {Provider} from 'react-redux';
import withRedux from 'next-redux-wrapper';
import {CookiesProvider} from 'react-cookie';
import {initStore} from '../store';
import '~/styles/rewrite-antd.less';
import '~/styles/global-styles.scss';
import 'react-phone-input-2/lib/style.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

class MyApp extends App {

    render() {
        const {Component, pageProps, store} = this.props;

        return (
            <CookiesProvider>
                <Provider store={store}>
                    <Component {...pageProps} />
                </Provider>
            </CookiesProvider>
        );
    }
}

export default withRedux(initStore)(MyApp);
