import {types as t} from './actions';

export const initState = {
  sortKeyValue: 'like',
  data: [],
  page: 1,
  totalCount: 0,
  totalPage: 0,
  allPage: false
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case t.GET_PROJECTS_REQUEST:
      return {
        loading: true
      };

    case t.GET_PROJECTS_SUCCESS:
      return {
        fetched: true,
        ...payload,
        data: payload.data,
        totalCount: payload.meta.pagination.total_count,
        totalPage: payload.meta.pagination.total_pages,
        page: payload.meta.pagination.current_page,
        allPage: payload.meta.pagination.total_pages === payload.meta.pagination.current_page
      };

    case t.GET_PROJECTS_FAIL:
      return {
        failure: true
      };
    case t.GET_NEXT_PROJECTS_REQUEST:
      return {
        ...state,
        loadingNext: true
      };

    case t.GET_NEXT_PROJECTS_SUCCESS:
      return {
        ...state,
        fetchedNext: true,
        ...payload,
        data: state.data.concat(payload.data),
        totalCount: payload.meta.pagination.total_count,
        totalPage: payload.meta.pagination.total_pages,
        page: payload.meta.pagination.current_page,
        allPage: payload.meta.pagination.total_pages <= payload.meta.pagination.current_page
      };

    case t.GET_NEXT_PROJECTS_FAIL:
      return {
        ...state,
        failureNext: true
      };

    default:
      return state;
  }
};
