import {types as t} from './actions';
import {emptyOrder} from '../../constants/emptyOrder';

export const initState = {
  orders: [],
  searchInfo: {},
  projects: {
    data: [],
    page: 1,
    totalCount: 0,
    totalPage: 0,
    allPage: false
  }
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case t.FETCH_STATION_USER_ORDERS_SUCCESS:
      return {
        ...state,
        $loadingNewOrder: false,
        $fetchedNewOrder: true,
        $failureNewOrder: false,
        orders: payload.orders.map(order => ({
          ...order,
          status: getStatus(order.status)
        }))
      };
    case t.FETCH_STATION_USER_ORDERS_FAIL:
      return {
        ...state,
        orders: initState.orders
      };
    case t.ADD_NEW_ORDER:
      return {
        ...state,
        orders: [emptyOrder, ...state.orders]
      };
    case t.SAVE_SEARCH_DATA:
      return ({
        ...state,
        searchInfo: payload || {}
      });
    case t.URL_SEND_ORDER_REQUEST:
      return ({
        ...state,
        $loadingNewOrder: true,
        $fetchedNewOrder: false,
        $failureNewOrder: false
      });
    case t.URL_SEND_ORDER_FAIL:
      return ({
        ...state,
        $loadingNewOrder: false,
        $fetchedNewOrder: false,
        $failureNewOrder: true
      });
    case t.FETCH_STATION_INFO_REQUEST:
      return ({
        ...state,
        $loading: true,
        $fetched: false,
        $failure: false
      });
    case t.FETCH_STATION_INFO_SUCCESS:
      return ({
        ...state,
        $loading: false,
        $fetched: true,
        $failure: false,
        data: {
          ...payload,
          styles: payload.styles.reduce(
              (arr, style) => arr.concat(
                  style.photos.map(photo => {
                    return {
                      ...photo,
                      label: style.label,
                      slug: style.slug,
                      styleId: style.id
                    };
                  })
              ),
              []
          )
        }
      });
    case t.FETCH_STATION_INFO_FAIL:
      return {
        ...state,
        $loading: false,
        $fetched: false,
        $failure: true
      };

    case t.GET_PROJECTS_REQUEST:
      return {
        ...state,
        projects: {
          loading: true
        }
      };

    case t.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: {
          fetched: true,
          data: payload.data,
          totalCount: payload.meta.pagination.total_count,
          totalPage: payload.meta.pagination.total_pages,
          page: payload.meta.pagination.current_page,
          allPage: payload.meta.pagination.total_pages === payload.meta.pagination.current_page
        }
      };

    case t.GET_PROJECTS_FAIL:
      return {
        ...state,
        projects: {
          failure: true
        }
      };
    case t.GET_NEXT_PROJECTS_REQUEST:
      return {
        ...state,
        projects: {
          ...state.projects,
          loadingNext: true
        }
      };

    case t.GET_NEXT_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: {
          fetchedNext: true,
          data: state.projects.data.concat(payload.data),
          totalCount: payload.meta.pagination.total_count,
          totalPage: payload.meta.pagination.total_pages,
          page: payload.meta.pagination.current_page,
          allPage: payload.meta.pagination.total_pages === payload.meta.pagination.current_page
        }
      };

    case t.GET_NEXT_PROJECTS_FAIL:
      return {
        ...state,
        projects: {
          failureNext: true
        }
      };

    default:
      return state;
  }
};

function getStatus(status) {
  switch (status) {
    case 'new_order' || 'processed':
      return 'new_order';

    case 'verified' || 'in_work':
      return 'verified';

    case 'ready':
      return 'ready';

    case 'canceled' || 'rewrite':
      return 'canceled';

    default:
      return status;

  }
}
