import {types as t} from './actions';

const initState = {
  data: []
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case t.SEARCH_SERVICES_REQUEST:
      return ({
        $loading: true
      });
    case t.SEARCH_SERVICES_SUCCESS:
      return ({
        $fetched: true,
        data: payload
      });
    case t.SEARCH_SERVICES_FAIL:
      return {
        $failure: true
      };
    default:
      return state;
  }
};
