import {types as t} from './actions';

const WIDTH = {
  desktop: 1200,
  laptop: 992,
  tablet: 768,
  phablet: 576
};

export const initState = {
  windowWidth: 'desktop'
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case t.SET_WIDTH:
      let windowWidth = 'phone';

      for (const key in WIDTH) {
        if (WIDTH[key] <= payload) {
          windowWidth = key;
          break;
        }
      }

      return {...state, windowWidth};

    default:
      return state;
  }
};
