import {types as t} from './actions';

export const initState = {
  open: false,
  data: {}
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case t.OPEN_MODAL:
      return {
        open: true,
        data: payload
      };

    case t.CLOSE_MODAL:
      return initState;

    default:
      return state;
  }
};
