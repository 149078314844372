import {asyncAction, createTypes} from 'redux-action-types';
import {ajax} from '../../api/ajax';
import {BOOKMARK_STATION} from '../../api/routes';

export const types = createTypes(
    'Bookmark.',
    asyncAction('ADD_BOOKMARK_STATION'),
    asyncAction('REMOVE_BOOKMARK_STATION')
);

export const addBookmarkStation = payload => dispatch => {
  dispatch({type: types.ADD_BOOKMARK_STATION_REQUEST});

  ajax({
    url: BOOKMARK_STATION(payload),
    method: 'POST',
    // data: {station_id: payload},
    cb: res => dispatch({type: types.ADD_BOOKMARK_STATION_SUCCESS}),
    err: err => dispatch({type: types.ADD_BOOKMARK_STATION_FAIL, payload: err})
  });
};

export const removeBookmarkStation = id => dispatch => {
  dispatch({type: types.REMOVE_BOOKMARK_STATION_REQUEST});

  ajax({
    url: BOOKMARK_STATION(id),
    method: 'DELETE',
    cb: res => dispatch({type: types.REMOVE_BOOKMARK_STATION_SUCCESS}),
    err: err => dispatch({type: types.REMOVE_BOOKMARK_STATION_FAIL, payload: err})
  });
};
