import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import {types as t} from './actions';

export const initState = {
  loading: false,
  filters: {},
  error: null,
  isMin: true,
  sort: null,
  selected: {
    type: [],
    benefits: [],
    body: [],
    network: null,
    radius: null,
    radiusInput: null,
    cost: [],
    costInput: []
  },
  active: {
    type: [],
    benefits: [],
    body: [],
    network: null,
    radius: null,
    cost: []
  },
  radiusIsEnable: true
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case t.SET_BODY:
      return {
        ...state,
        selected: {
          ...state.selected,
          body: payload
        }
      };

    case t.SET_STATION_TYPE:
      return {
        ...state,
        selected: {
          ...state.selected,
          type: payload
        }
      };

    case t.GET_FILTERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case t.GET_FILTERS_SUCCESS: {
      const {
        distance: {params: {max: dMax} = {}} = {},
        hourly_rate: {
          params: {max: hrMax, min: hrMin}
        }
      } = payload;
      const {
        active: {radius: aRadius, cost: aCost}
      } = state;
      const newState = {
        ...state,
        loading: false,
        filters: payload
      };

      if (isNull(aRadius)) {
        newState.selected = {
          ...newState.selected,
          radius: dMax,
          radiusInput: dMax
        };
        newState.active = {
          ...newState.active,
          radius: dMax
        };
      }

      if (isEmpty(aCost)) {
        newState.selected = {
          ...newState.selected,
          cost: [hrMin, hrMax],
          costInput: [hrMin, hrMax]
        };
        newState.active = {
          ...newState.active,
          cost: [hrMin, hrMax]
        };
      }

      return newState;
    }

    case t.SET_MAX_RADIUS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          distance: {
            ...state.filters.distance,
            params: {
              ...state.filters.distance.params,
              max: payload * 2
            }
          }
        }
      };
    }

    case t.GET_FILTERS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };

    case t.TOGGLE_SIZE:
      return {
        ...state,
        isMin: !state.isMin
      };

    case t.SET_TOGGLE_SIZE:
      return {
        ...state,
        isMin: payload
      };

    case t.SET_ALL_FILTERS:
      const {sort, radius, cost, ...otherPayload} = payload;
      const {
        distance = {},
        hourly_rate = {},
        sort: {params: {default: defaultSort} = {}} = {}
      } = state.filters;
      const {params: dParams = {}} = distance;
      const {params: hrParams = {}} = hourly_rate;
      const {max: costMax, min: costMin} = hrParams;
      const {max: distMax} = dParams;
      const newRadius = radius || distMax;
      const newCost = cost ? [...cost] : [costMin, costMax];

      otherPayload.radius = newRadius;
      otherPayload.cost = newCost;

      return {
        ...state,
        sort: sort || defaultSort,
        selected: {
          ...state.selected,
          ...otherPayload,
          radiusInput: newRadius,
          costInput: newCost
        },
        active: {...state.active, ...otherPayload}
      };

    case t.TOGGLE_TYPE:
      const newType = getSelected('type', state, payload);
      return {
        ...state,
        selected: {
          ...state.selected,
          type: [...newType]
        }
      };

    case t.TOGGLE_BENEFITS:
      const newBenefits = getSelected('benefits', state, payload);
      return {
        ...state,
        selected: {
          ...state.selected,
          benefits: [...newBenefits]
        }
      };

    case t.TOGGLE_BODY:
      const newBody = getSelected('body', state, payload);
      return {
        ...state,
        selected: {
          ...state.selected,
          body: [...newBody]
        }
      };

    case t.TOGGLE_NETWORK:
      return {
        ...state,
        selected: {
          ...state.selected,
          network: payload
        }
      };

    case t.SET_RADIUS:
      return {
        ...state,
        selected: {
          ...state.selected,
          radius: payload
        }
      };

    case t.SET_RADIUS_INPUT:
      return {
        ...state,
        selected: {
          ...state.selected,
          radiusInput: payload
        }
      };

    case t.MAKE_RADIUS_ACTIVE:
      return {
        ...state,
        active: {
          ...state.active,
          radius: state.selected.radius
        }
      };

    case t.ENABLE_RADIUS:
      return {
        ...state,
        radiusIsEnable: true
      };

    case t.DISABLE_RADIUS:
      return {
        ...state,
        radiusIsEnable: false
      };

    case t.SET_COST:
      return {
        ...state,
        selected: {
          ...state.selected,
          cost: payload
        }
      };

    case t.SET_COST_INPUT:
      return {
        ...state,
        selected: {
          ...state.selected,
          costInput: payload
        }
      };

    case t.SET_SORT:
      return {
        ...state,
        sort: payload
      };

    case t.APPLY_FILTERS: {
      const {selected} = state;
      // const { radiusInput, costInput, ...active } = selected;

      return {
        ...state,
        active: {...selected},
        isMin: true
      };
    }

    case t.RESET_FILTERS:
      const {
        hourly_rate: {params: {min: cMin, max: cMax} = {}} = {}
      } = state.filters;
      const optionsSelected = {
        radiusInput: payload,
        costInput: [cMin, cMax]
      };
      const optionsActive = {
        radius: payload,
        cost: [cMin, cMax]
      };

      return {
        ...state,
        selected: {
          ...initState.selected,
          ...optionsSelected,
          ...optionsActive
        },
        active: {
          ...initState.active,
          ...optionsActive
        }
      };

      // Hallway cases
    case t.SET_BENEFITS:
      return {
        ...state,
        selected: {
          ...state.selected,
          benefits: payload
        }
      };
    case t.RESET_BENEFITS:
      return {
        ...state,
        selected: {
          ...state.selected,
          benefits: initState.selected.benefits
        }
      };

    case t.ACCEPT_BENEFITS:
      return {
        ...state,
        active: {
          ...state.active,
          benefits: state.selected.benefits
        }
      };

    case t.SAVE_FILTERS:
      return {
        ...state,
        active: {...state.active, ...state.selected}
      };


    default:
      return state;
  }
};

function getSelected(type, state, payload) {
  let newSelected = [...state.selected[type], payload];

  if (state.selected[type].includes(payload)) {
    newSelected = state.selected[type].filter(i => i !== payload);
  }

  return newSelected;
}
