import {asyncAction, createTypes} from 'redux-action-types';
import {ajax} from '../../api/ajax';
import {FETCH_PROJECT} from '../../api/routes';

export const types = createTypes(
    'PROJECT.',
    asyncAction('GET')
);

export const getProject = (slug) => (dispatch) => {

  dispatch({type: types.GET_REQUEST});

  return ajax({
    url: FETCH_PROJECT(slug),
    cb: res => dispatch({
      type: types.GET_SUCCESS,
      payload: res
    }),
    err: err => dispatch({
      type: types.GET_FAIL,
      payload: err
    })
  });
};
