import {asyncAction, createTypes} from 'redux-action-types';
import querystring from 'querystring';
import {ajax} from '../../api/ajax';
import {SEARCH_SERVICES_BY_STATION, URL_SEARCH_TERMS} from '../../api/routes';

export const types = createTypes(
    'Services-info.',
    asyncAction('SEARCH_SERVICES')
);

export const searchServices = (stationId, searchText) => (dispatch) => {

  dispatch({type: types.SEARCH_SERVICES_REQUEST});

  return ajax({
    url: `${SEARCH_SERVICES_BY_STATION}?station_id=${stationId}${searchText ? '&term=' + searchText : ''}`,
    cb: res => dispatch({
      type: types.SEARCH_SERVICES_SUCCESS,
      payload: res
    })
  });
};


export const searchServicesByText = (
    text
) => (dispatch) => {
  const str = querystring.encode({
    limit_service: 10,
    limit_problem: 10,
    limit_station: 20,
    limit_symptom: 10,
    tab_id: 1
  });
  const url = `${URL_SEARCH_TERMS}?${str}${text ? `&text=${text}` : ''}`;

  dispatch({type: types.SEARCH_SERVICES_REQUEST});

  return ajax({
    url,
    cb: res => dispatch({
      type: types.SEARCH_SERVICES_SUCCESS,
      payload: res.data
    })
  });
};
