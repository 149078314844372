import {types as t} from './actions';

export const initState = {
  info: {},
  meta: {}
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case t.GET_COUNTRY_INFO_SUCCESS:
      return {
        ...state,
        info: {...payload.info},
        meta: payload.meta
      };

    case t.GET_COUNTRY_INFO_FAIL:
      return {...state, infoError: payload, info: {}};

    default:
      return state;
  }
};
