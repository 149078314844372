import {asyncAction, createTypes} from 'redux-action-types';

import {ajax} from '../../api/ajax';
import {SEARCH_BRANDS_BY_STATION} from '../../api/routes';


export const types = createTypes(
    'Brands-info.',
    asyncAction('SEARCH_BRANDS')
);

export const searchBrands = (stationId, searchText) => (dispatch) => {

  dispatch({type: types.SEARCH_BRANDS_REQUEST});

  return ajax({
    url: `${SEARCH_BRANDS_BY_STATION}?stationId=${stationId}${searchText ? '&term=' + searchText : ''}`,
    cb: res => dispatch({
      type: types.SEARCH_BRANDS_SUCCESS,
      payload: res
    })
  });
};
