import {types as t} from './actions';
import keyBy from 'lodash/keyBy';

export const initState = {
  sortKeyValue: 'like',
  filters: {},
  selected: {
    objectType: [],
    brands: [],
    brandServices: [],
    status: [],
    styles: [],
    footage: undefined,
    endYear: undefined
  },
  active: {
    objectType: [],
    brands: [],
    brandServices: [],
    status: [],
    styles: [],
    footage: undefined,
    endYear: undefined
  }
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case t.SET_SORT_VALUE:
      return {
        ...state,
        sortKeyValue: payload
      };

    case t.GET_FILTERS_REQUEST:
      return {
        ...state,
        filters: {
          ...state.filters,
          loading: true
        }
      };

    case t.GET_FILTERS_SUCCESS: {
      return {
        ...state,
        filters: {
          ...keyBy(payload, 'id'),
          fetched: true
        }
      };
    }

    case t.GET_FILTERS_FAIL:
      return {
        ...state,
        filters: {failure: true}
      };

    case t.SET_FOOTAGE:
      return {
        ...state,
        selected: {
          ...state.selected,
          footage: [...payload]
        }
      };

    case t.SET_END_YEAR:
      return {
        ...state,
        selected: {
          ...state.selected,
          endYear: [...payload]
        }
      };

    case t.SET_CITY:
      return {
        ...state,
        selected: {
          ...state.selected,
          city: payload
        }
      };

    case t.SELECT_OBJECT_TYPE:
      return {
        ...state,
        selected: {
          ...state.selected,
          objectType: [...state.selected.objectType, payload]
        }
      };

    case t.DESELECT_OBJECT_TYPE:
      return {
        ...state,
        selected: {
          ...state.selected,
          objectType: [...state.selected.objectType.filter(item => item !== payload)]
        }
      };

    case t.SELECT_BRAND:
      return {
        ...state,
        selected: {
          ...state.selected,
          brands: [...state.selected.brands, payload]
        }
      };

    case t.DESELECT_BRAND:
      return {
        ...state,
        selected: {
          ...state.selected,
          brands: [...state.selected.brands.filter(item => item !== payload)]
        }
      };

    case t.SELECT_BRAND_SERVICES:
      return {
        ...state,
        selected: {
          ...state.selected,
          brandServices: [...state.selected.brandServices, payload]
        }
      };

    case t.DESELECT_BRAND_SERVICES:
      return {
        ...state,
        selected: {
          ...state.selected,
          brandServices: [...state.selected.brandServices.filter(item => item !== payload)]
        }
      };

    case t.SELECT_STATUS:
      return {
        ...state,
        selected: {
          ...state.selected,
          status: [...state.selected.status, payload]
        }
      };

    case t.DESELECT_STATUS:
      return {
        ...state,
        selected: {
          ...state.selected,
          status: [...state.selected.status.filter(item => item !== payload)]
        }
      };

    case t.SELECT_STYLE:
      return {
        ...state,
        selected: {
          ...state.selected,
          styles: [...state.selected.styles, payload]
        }
      };

    case t.DESELECT_STYLE:
      return {
        ...state,
        selected: {
          ...state.selected,
          styles: [...state.selected.styles.filter(item => item !== payload)]
        }
      };

    case t.APPLY_FILTERS:
      return {
        ...state,
        active: {
          ...state.selected
        }
      };

    case t.RESET_FILTERS:
      return {
        ...state,
        selected: {
          ...initState.selected
        },
        active: {
          ...initState.active
        }
      };

    default:
      return state;
  }
};
