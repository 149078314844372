import {types as t} from './actions';

export const initState = {
  loading: false,
  tabs: [],
  error: null,
  onRequestSuccess: false
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case t.GET_TABS_REQUEST:
      return {...state, loading: true, error: null};

    case t.GET_TABS_SUCCESS:
      return {...state, loading: false, onRequestSuccess: true, tabs: [...payload]};

    case t.GET_TABS_FAIL:
      return {...state, loading: false, error: payload};

    default:
      return state;
  }
};
