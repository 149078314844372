import {types as t} from './actions';

export const initState = {
  isFullMobileMap: false,
  headerHeight: 0,
  resultsRightPos: 0,
  inputMain: {},
  inputStyles: {},
  popularServices: {
    isOpen: false,
    isActive: null
  },
  isSearchOptions: false,
  userCurrentPosition: {},
  isErrorPage: false,
  isMount: false,
  styles: [],
  isSpinner: false
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case t.TOGGLE_SPINNER: {
      return {
        ...state,
        isSpinner: !state.isSpinner
      }
    }
    case t.SET_FULL_MOBILE_MAP:
      return {...state, isFullMobileMap: true};

    case t.REMOVE_FULL_MOBILE_MAP:
      return {...state, isFullMobileMap: false};

    case t.SET_VALUE_OF_SEARCH_PAGE_HEADER:
      return {...state, headerHeight: payload};

    case t.SET_VALUE_OF_SEARCH_PAGE_RESULTS:
      return {...state, resultsRightPos: payload};

    case t.SET_INPUT_MAIN_PROPS:
      return {...state, inputMain: payload};

    case t.SET_INPUT_STYLES_PROPS:
      return {...state, inputStyles: payload};

    case t.SET_POPULAR_SERVICES:
      return {...state, popularServices: payload};

    case t.TOGGLE_SEARCH_OPTIONS:
      return {...state, isSearchOptions: !state.isSearchOptions};

    case t.SET_USER_CURRENT_POSITION:
      return {...state, userCurrentPosition: payload};

    case t.CHANGE_ERROR_PAGE_STATE:
      return {...state, isErrorPage: payload};

    case t.SET_IS_MOUNT:
      return {...state, isMount: true};
    case t.ADD_STYLES:
      return {...state, styles: [...state.styles, payload]};

    case t.REMOVE_STYLES:
      const newStyles = state.styles.filter(val => val.id !== payload.id);
      return {...state, styles: newStyles};

    case t.CLEAR_ALL_STYLES:
      return {...state, styles: initState.styles};

    default:
      return state;
  }
};
