import {asyncAction, createTypes} from 'redux-action-types';
import {ajax} from './../../api/ajax';
import {FETCH_PROJECTS_FILTERS} from './../../api/routes';

export const types = createTypes(
    'GalleryFilters.',
    'SET_SORT_VALUE',
    'SET_CITY',
    'SET_FOOTAGE',
    'SET_END_YEAR',
    'SELECT_OBJECT_TYPE',
    'DESELECT_OBJECT_TYPE',
    'SELECT_BRAND',
    'DESELECT_BRAND',
    'SELECT_BRAND_SERVICES',
    'DESELECT_BRAND_SERVICES',
    'SELECT_STATUS',
    'DESELECT_STATUS',
    'SELECT_STYLE',
    'DESELECT_STYLE',
    asyncAction('GET_FILTERS'),
    'RESET_FILTERS',
    'APPLY_FILTERS'
);

export const setSortValue = key => dispatch => dispatch({
  type: types.SET_SORT_VALUE,
  payload: key
});

export const getFilters = () => (dispatch, getState) => {
  const {
    locate: {language}
  } = getState();

  dispatch({type: types.GET_FILTERS_REQUEST});

  return ajax({
    url: FETCH_PROJECTS_FILTERS,
    cb: res => dispatch({
      type: types.GET_FILTERS_SUCCESS,
      payload: res.data
    }),
    err: err => dispatch({
      type: types.GET_FILTERS_FAIL,
      payload: err
    }),
    language: language === 'uk' ? 'UA' : language.toUpperCase()
  });
};

export const setCity = payload => ({
  type: types.SET_CITY,
  payload
});
export const setFootage = payload => ({
  type: types.SET_FOOTAGE,
  payload
});
export const setEndYear = payload => ({
  type: types.SET_END_YEAR,
  payload
});
export const selectObjectType = payload => ({
  type: types.SELECT_OBJECT_TYPE,
  payload
});
export const deselectObjectType = payload => ({
  type: types.DESELECT_OBJECT_TYPE,
  payload
});
export const selectBrand = payload => ({
  type: types.SELECT_BRAND,
  payload
});
export const deselectBrand = payload => ({
  type: types.DESELECT_BRAND,
  payload
});
export const selectBrandServices = payload => ({
  type: types.SELECT_BRAND_SERVICES,
  payload
});
export const deselectBrandServices = payload => ({
  type: types.DESELECT_BRAND_SERVICES,
  payload
});
export const selectStatus = payload => ({
  type: types.SELECT_STATUS,
  payload
});
export const deselectStatus = payload => ({
  type: types.DESELECT_STATUS,
  payload
});
export const selectStyle = payload => ({
  type: types.SELECT_STYLE,
  payload
});
export const deselectStyle = payload => ({
  type: types.DESELECT_STYLE,
  payload
});
export const resetFilters = () => ({type: types.RESET_FILTERS});
export const applyFilters = () => ({type: types.APPLY_FILTERS});

