export const emptyOrder = {
    station_id: '',
    phone: '',
    service_names: '',
    date: '',
    time: '',
    brand_id: '',
    brand_model_id: '',
    name: '',
    call_me: '',
    referer_key: '',
    additional_info: '',
}
