import querystring from 'querystring';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {asyncAction, createTypes} from 'redux-action-types';
import {ajax} from '../../api/ajax';
import {FETCH_PROJECTS} from '../../api/routes';

const countProjectOnPage = 25;

export const types = createTypes(
    'Gallery.',
    asyncAction('GET_PROJECTS'),
    asyncAction('GET_NEXT_PROJECTS')
);

export const getNextProjects = () => (dispatch, getState) => {

  const {page} = getState().gallery;
  const {sortKeyValue, active} = getState().galleryFilters;

  dispatch({type: types.GET_NEXT_PROJECTS_REQUEST});

  return ajax({
    url: FETCH_PROJECTS + `?page_size=${countProjectOnPage}&page_number=${page + 1}&sortBy=${sortKeyValue}${prepareQuery(active)}`,
    cb: res => dispatch({
      type: types.GET_NEXT_PROJECTS_SUCCESS,
      payload: res
    }),
    err: err => dispatch({
      type: types.GET_NEXT_PROJECTS_FAIL,
      payload: err
    })
  });
};

export const getProjects = (active) => (dispatch, getState) => {

  const {sortKeyValue} = getState().galleryFilters;
  const filterQuery = active ? prepareQuery(active) : '';

  dispatch({type: types.GET_PROJECTS_REQUEST});

  return ajax({
    url: FETCH_PROJECTS + `?page_size=${countProjectOnPage}&page_number=1&sortBy=${sortKeyValue}${filterQuery}`,
    cb: res => dispatch({
      type: types.GET_PROJECTS_SUCCESS,
      payload: res
    }),
    err: err => dispatch({
      type: types.GET_PROJECTS_FAIL,
      payload: err
    })
  });
};

const prepareQuery = (active) => {

  const query = {
    brand_service_ids: active.brandServices.join(','),
    city_id: get(active, 'city.key'),
    end_date_range: get(active, 'endYear', [])
        .join(','),
    footage_range: get(active, 'footage', [])
        .join(','),
    style_ids: active.styles.join(','),
    status_ids: active.status.join(','),
    object_ids: active.objectType.join(','),
    brand_ids: active.brands.join(',')
  };

  return `&${querystring.encode({...omitBy(query, isEmpty)})}`;
};
