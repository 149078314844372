import {types as t} from './actions';

export const initState = {
  selected: {
    main: [],
    brand: {},
    master: {},
    place: {},
    date: null,
    time: null,
    // This fields for Hallway
    quadrature: '',
    price: '',
    styles: []
  },
  active: {
    main: [],
    brand: {},
    master: {},
    place: {
      search_by_map: true
    },
    date: null,
    time: null,
    // Add this field for URL changing
    locationSlug: '',
    locationId: '',
    locationName: '',
    // This fields for Hallway
    quadrature: '',
    price: '',
    styles: []
  },
  remoteInsurance: '',
  review: '',
  zoom: 17,
  terms: {
    services: [],
    problems: [],
    stations: [],
    synonyms: []
  },
  carModels: {
    my: [],
    brands: [],
    models: []
  },
  styles: []
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case t.SET_ALL_FIELDS:
      return {
        ...state,
        selected: {...state.selected, ...payload},
        active: {...state.active, ...payload}
      };

    case t.SET_MAIN:
      return {
        ...state,
        selected: {
          ...state.selected,
          main: payload
        }
      };

    case t.SET_MASTER:
    return {
      ...state,
      selected: {
        ...state.selected,
        master: payload
      }
    };

    case t.SET_BRAND:
      return {...state, selected: {...state.selected, brand: payload}};

    case t.SET_PLACE:
      return {
        ...state,
        selected: {
          ...state.selected,
          place: payload
        }
      };

    case t.SET_PLACE_ACTIVE:
      return {
        ...state,
        selected: {
          ...state.selected,
          place: {
            lat: state.selected.place.lat,
            lng: state.selected.place.lng,
            ...payload
          }
        },
        active: {
          ...state.active,
          place: {
            lat: state.active.place.lat,
            lng: state.active.place.lng,
            ...payload
          }
        }
      };

    case t.SET_ZOOM:
      return {...state, zoom: payload};

    case t.SET_DATE:
      return {...state, selected: {...state.selected, date: payload}};

    case t.SET_TIME:
      return {...state, selected: {...state.selected, time: payload}};

    case t.SET_REMOTE_INSURANCE:
      return {...state, remoteInsurance: payload};

    case t.SET_REVIEW:
      return {...state, review: payload};

    case t.SAVE_FIELDS:
      return {...state, active: {...state.active, ...state.selected}};

    case t.CLEAR_ALL_TABS:
      return {
        ...initState,
        selected: {
          ...initState.selected,
          main: [],
          brand: {},
          place: {},
          date: null,
          time: null
        }
      };

    case t.SET_LOCATION_NAME:
      return {
        ...state,
        active: {
          ...state.active,
          locationName: payload
        }
      };

    case t.SET_LOCATION_SLUG:
      return {
        ...state,
        active: {
          ...state.active,
          locationSlug: payload
        }
      };

    case t.SET_LOCATION_ID:
      return {
        ...state,
        active: {
          ...state.active,
          locationId: payload
        }
      };

    case t.CLEAR_ALL_TERMS:
      return {
        ...state,
        terms: initState.terms
      };

      // case t.SET_ALL_TERMS:
      //   return {
      //     ...state,
      //     terms: payload.reduce(
      //       (obj, term) => {
      //         switch (term.type) {
      //           case 1:
      //             obj.services = obj.services.concat(term);
      //             break;
      //           case 2:
      //             obj.problems = obj.problems.concat(term);
      //             break;
      //           case 3:
      //             obj.stations = obj.stations.concat(term);
      //             break;
      //           case 4:
      //             obj.synonyms = obj.synonyms.concat(term);
      //             break;
      //           default:
      //             break;
      //         }

      //         return obj;
      //       },
      //       { ...initState.terms }
      //     )
      //   };

    case t.CLEAR_CAR_MODELS:
      return {
        ...state,
        carModels: initState.carModels
      };

    case t.SET_CAR_MODELS:
      return {
        ...state,
        carModels: payload.reduce(
            (obj, car) => {
              switch (car.type) {
                case 1:
                  obj.my = obj.my.concat(car);
                  break;
                case 2:
                  obj.brands = obj.brands.concat(car);
                  break;
                case 3:
                  obj.models = obj.models.concat(car);
                  break;
                default:
                  break;
              }

              return obj;
            },
            {...initState.carModels}
        )
      };

    case t.CLEAR_BRAND:
      return {
        ...state,
        selected: {
          ...state.selected,
          brand: initState.selected.brand
        }
      };

      // Cases of Hallway
    case t.SET_QUADRATURE:
      return {
        ...state,
        selected: {
          ...state.selected,
          quadrature: payload
        }
      };

    case t.SET_PRICE:
      return {
        ...state,
        selected: {
          ...state.selected,
          price: payload
        }
      };

    case t.ADD_STYLES:
      return {
        ...state,
        selected: {
          ...state.selected,
          styles: [...state.selected.styles, payload]
        }
      };

    case t.REMOVE_STYLES:
      return {
        ...state,
        selected: {
          ...state.selected,
          styles: state.selected.styles.filter(val => val.id !== payload.id)
        }
      };

    case t.SET_ACTIVE_STYLES:
      return {
        ...state,
        active: {
          ...state.active,
          styles: state.selected.styles
        }
      };

    case t.CANCEL_CHOOSE_STYLES:
      return {
        ...state,
        selected: {
          ...state.selected,
          styles: state.active.styles
        }
      };

    case t.CLEAR_ALL_STYLES:
      return {
        ...state,
        selected: {
          ...state.selected,
          styles: initState.selected.styles
        }
      };

    case t.SET_STYLES:
      return {
        ...state,
        styles: payload.reduce(
            (arr, style) => arr.concat(
                style.photos.map(photo => {
                  return {
                    ...photo,
                    label: style.label,
                    slug: style.slug,
                    styleId: style.id
                  };
                })
            ),
            []
        )
      };

    case t.SET_STYLES_FORCE:
      return {
        ...state,
        selected: {
          ...state.selected,
          styles: payload
        }
      };

    default:
      return state;
  }
};
