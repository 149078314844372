import isEmpty from 'lodash/isEmpty';
import {types as t} from './actions';

export const initState = {
  user: {},
  authorized: false,
  helpLinks: [],
  registerUser: {},
  login: {
    loading: false,
    error: null
  },
  logout: {
    loading: false,
    error: null
  },
  register: {
    loading: false,
    error: null
  }
};

export default (state = initState, {type, payload}) => {
  switch (type) {

    case t.SET_USER:
      return {...state, user: payload, authorized: !isEmpty(payload)};

    case t.SET_HELP_LINKS:
      return {...state, helpLinks: payload};

      // LOGIN
    case t.LOGIN_REQUEST:
      return {...state, login: {loading: true, error: null}};

    case t.LOGIN_SUCCESS:
      return {
        ...state,
        login: {loading: false},
        user: payload.data ? {...payload.data} : {},
        authorized: true,
        helpLinks: payload.meta.help_links
      };

    case t.LOGIN_FAIL:
      return {...state, login: {loading: false, error: {...payload}}};

      // LOGOUT
    case t.LOGOUT_REQUEST:
      return {...state, logout: {loading: true, error: null}};

    case t.LOGOUT_SUCCESS:
      return {...state, logout: {loading: false}, user: {}, authorized: false};

    case t.LOGOUT_FAIL:
      return {...state, logout: {loading: false, error: {...payload}}};

      // REGISTER
    case t.REGISTER_REQUEST:
      return {...state, register: {loading: true, error: null}};

    case t.REGISTER_SUCCESS:
      return {...state, register: {loading: false}, registerUser: {...payload.data}};

    case t.REGISTER_FAIL:
      return {...state, register: {loading: false, error: {...payload}}};

    default:
      return state;
  }
};
